.player-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: none;
  transition: background-color 0.5s ease;
  &:focus {
    background-color: none;
    outline: none;
  }
}

.player-button:hover, .player-button.focus {
  background-color: #dadada;
}

.player-button svg {
  height: 12px;
}