$enable-responsive-font-sizes: true;
@import "~bootstrap/scss/bootstrap";

body {
  background-color: #f5f5f5;
  height: 100vh;
  height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

#root {
  height: 100%;
}

.clickable {
  cursor: pointer;
}