.raps-page {
  height: 100%;
  box-sizing: border-box;
  .nav {
    height: 56px;
  }
  .raps-player {
    width: 100%;
    height: 55px;
  }
  .raps-list {
    display: flex;
    justify-content: center;
    padding: 44px 0;
    height: calc(100% - 56px - 55px);
    overflow: auto;
    .row {
      width: 100%;
    }
  }
}