.filter-menu {
  height: 660px;
  width: 350px;
  max-height: 60vh;
  max-width: calc(100vw - 20px);
  position: fixed;
  right: 10px;
  bottom: 54px;
  pointer-events: none;
  transition: opacity .35s, transform .35s;
  transition-timing-function: cubic-bezier(0,0,0,1.3);
  transform: translateY(150px);
  opacity: 0;
  &.show {
    pointer-events: auto;
    transform: translateY(0);
    opacity: 1;
  }
}

.filter-menu .card {
  height: 100%;
}

.filter-menu button.close {
  &:focus {
    outline: none;
  }
}

.filter-menu .card ul {
  border-top: none;
  overflow: auto;
  li {
    background: none;
  }
}