.rap-list {
  display: flex;
  align-items: center;
  .list-group-item {
    margin: 5px 0;
    border-radius: 3px;
    border-width: 1px;
    + .list-group-item {
      border-top-width: 1px;
    }
  }
  li h5 {
    margin: 0;
  }
}