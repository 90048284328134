.thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.thumbnail.empty {
  background-color: #e6e6e6;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  border-radius: 0.2rem;
}