.player {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: rgb(241, 243, 244);
  border-top: 1px solid #e6e6e6;
  .button {
    padding-right: 10px;
  }
  .player-thumbnail {
    position: relative;
    width: 54px;
    .loading {
      z-index: 1;
    }
  }
  .react-audio-player {
    width: 100%;
    outline: none;
  }
}